import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Image, Divider, Layout, Button, Form, Input, Tabs, message } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { TbMail } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa";

import { auth } from "../../firebase";
import { sendSignInLinkToEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { usePostProfileMutation, useRegisterMutation, useLoginMutation } from "../../features/api/apiSlice";
import {HiOutlineKey} from "react-icons/hi";

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const navigate = useNavigate();
  const [postProfile] = usePostProfileMutation();
  const [register] = useRegisterMutation();
  const [login] = useLoginMutation();

  // Sign in with Magic Link
  const handleMagicLink = async (values) => {
    setLoading(true);

    const actionCodeSettings = {
      url: `${process.env.REACT_APP_APP_URI}/auth/email/magic-link-confirm`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, values.email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", values.email);
      message.success("Magic link sent to your email.");
      navigate("/auth/email/magic-link-follow");
    } catch (error) {
      console.error(error);
      message.error("Failed to send magic link.");
    } finally {
      setLoading(false);
    }
  };

  // Google Sign-In
  const handleGoogleAuth = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

    try {
      const result = await signInWithPopup(auth, provider);

      const userEmail = result.user.email;
      localStorage.setItem("email", userEmail);

    } catch (error) {
      console.error(error);
      message.error("Google sign-in failed.");
    }
  };

  // Register User
  const handleRegister = async (values) => {
    setLoading(true);

    try {
      const { data } = await register(values).unwrap();
      message.success("Registration successful. You can now log in.");
      setIsRegistering(false);
    } catch (error) {
      console.error(error);
      message.error("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Login User
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      // await window.ReactNativeWebView.postMessage(await login(values));
      const data = (await login(values)).data; // Call login mutation

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: 'saveJWTToken', content: data.user.token })
        );
      }

      else {
        localStorage.setItem("authToken", data.user.token); // Store token in localStorage
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("username", data.user.name);
      }

      message.success("Login successful.");
      navigate("/projects"); // Replace with your desired route
    } catch (error) {
      console.error(error);
      message.error("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  return (
      <Layout style={{ minHeight: "100vh" }}>
        <Flex vertical align="center" justify="center" style={{ minHeight: "100vh" }}>
          <Image preview={false} width={100} src="../InframentIcon-no-bg.png" />
          <h1>{isRegistering ? "Register" : "Sign In"}</h1>
          <div style={{ width: 320, marginBottom: 16 }}>
            {isRegistering
                ? "Create your account by filling in the details below."
                : "Welcome back to Inframent! Please enter your details below to sign in."}
          </div>
          <Tabs
              activeKey={isRegistering ? "register" : "login"}
              onChange={(key) => setIsRegistering(key === "register")}
              style={{ width: 320, marginLeft: 'auto', marginRight: 'auto' }}
              centered
          >
            {/* Login Tab */}
            <Tabs.TabPane key="login" tab="Login">
              <Form onFinish={handleLogin} autoComplete="on">
                <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please provide your Email" },
                      { type: "email", message: "Enter a valid email address" },
                    ]}
                >
                  <Input prefix={<TbMail style={{marginRight: 5}}/>} placeholder="Enter your Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Please enter your Password" }]}
                >
                  <Input.Password prefix={<HiOutlineKey style={{marginRight: 5}} />} placeholder="Enter your Password" />
                </Form.Item>
                <Form.Item>
                  <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                  >
                    Log In
                  </Button>
                </Form.Item>
                {/*<Divider plain>OR</Divider>*/}
                {/*<Button*/}
                {/*  style={{ width: "100%" }}*/}
                {/*  type="default"*/}
                {/*  onClick={handleMagicLink}*/}
                {/*  loading={loading}*/}
                {/*>*/}
                {/*  Continue with Email Magic Link*/}
                {/*</Button>*/}
                <Divider plain>OR</Divider>
                <Button
                    style={{ width: "100%" }}
                    icon={<GoogleOutlined />}
                    onClick={handleGoogleAuth}
                >
                  Continue with Google
                </Button>
              </Form>
            </Tabs.TabPane>

            {/* Register Tab */}
            <Tabs.TabPane key="register" tab="Register">
              <Form onFinish={handleRegister} autoComplete="on">
                {/* First Name Field */}
                <Form.Item
                    name="first_name"
                    rules={[{ required: true, message: "Please provide your First Name" }]}
                >
                  <Input prefix={<FaRegUser style={{marginRight: 5}}/>} placeholder="First Name" />
                </Form.Item>

                {/* Last Name Field */}
                <Form.Item
                    name="last_name"
                    rules={[{ required: true, message: "Please provide your Last Name" }]}
                >
                  <Input prefix={<FaRegUser style={{marginRight: 5}}/>} placeholder="Last Name" />
                </Form.Item>

                {/* Email Field */}
                <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please provide your Email" },
                      { type: "email", message: "Enter a valid email address" },
                    ]}
                >
                  <Input prefix={<TbMail style={{marginRight: 5}}/>} placeholder="Email" />
                </Form.Item>

                {/* Password Field */}
                <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Please enter your Password" },
                      { min: 6, message: "Password must be at least 6 characters" },
                    ]}
                >
                  <Input.Password prefix={<HiOutlineKey style={{marginRight: 5}}/>} placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                  >
                    Register
                  </Button>
                </Form.Item>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Flex>
      </Layout>
  );
}
